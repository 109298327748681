import React from 'react'
import BankingHome from './BankingHome/Bankinghome'
import WhatisBanking from './WhatisBanking/WhatisBanking'
import BankingReq from './BankingRequirement/BankingReq'
import BankingServices from './BankingServices/BankingServices'
import BankingSegment from './BankingSegment/BankingSegment'



const Banking = () => {
    return (
        <div className='about'>
            <BankingHome/>
            <WhatisBanking/>
            <BankingReq/> 
             <BankingSegment/>
            {/* <BankingServices/> */}
          
        </div>
    )
}

export default Banking