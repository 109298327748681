import React from 'react'
import { Link } from 'react-router-dom'
import './Enquiry.css'

const Enquiry = () => {
    return (
        <>
            <div className='enquiry-top-container text-center text-white py-4'>
                <div className='enquiry-top'>
                    <h3 className='m-0'>
                    For Partnership and Association enquiries, Please contact us at
                    </h3>
                    <Link className='enquiry-no'>020-711-72777</Link>
                    <h3 className='m-0'>
                        or write to us at <span className='enquiry-span text-dark'>support@csp.in</span>
                    </h3>
                </div>
            </div>
        </>
    )
}

export default Enquiry