import React from 'react'
import './Values.css'
import TransImg from '../../AboutNew/Assets/transparency.png'
import TrustImg from '../../AboutNew/Assets/Trust.png'
import TogetherImg from '../../AboutNew/Assets/united.png'

const Values = () => {
    return (
        <div className='Values-container'>
            <div className='Values-main-container'>
                <div className='d-flex justify-content-center'>
                    <h2> Core Values</h2>
                </div>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-sm-12 col-12 Values-cards py-5 px-3'>
                        <div className='Values-card d-flex flex-column align-items-center text-center py-2'>
                            <div className='csp-card-icons'>
                                <img className='about-mission-img' src={TrustImg} alt='mission' />
                            </div>
                            <div className='csp-heading'>
                                <h5>TRUST</h5>
                            </div>
                            <div className='icon-p'>
                                We have ‘Trust’ embedded in the fabric of our Organization
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-12 col-sm-12 col-12 Values-cards py-5 px-3'>
                        <div className='Values-color-card d-flex flex-column align-items-center text-center py-2'>
                            <div className='csp-card-icons'>
                                <img className='about-mission-img' src={TransImg} alt='mission' />
                            </div>
                            <div className='csp-heading'>
                                <h5>
                                    TRANSPARENCY
                                </h5>
                            </div>
                            <div className='icon-p'>
                                We ensure Transparency in all our Organizational processes.
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-4 col-md-12 col-sm-12 col-12 Values-cards py-5 px-3'>
                        <div className='Values-card d-flex flex-column align-items-center text-center py-2'>
                            <div className='csp-card-icons'>
                                <img className='about-mission-img' src={TogetherImg} alt='mission' />
                            </div>
                            <div className='csp-heading'>
                                <h5>
                                    TOGETHERNESS
                                </h5>
                            </div>
                            <div className='icon-p'>
                                ‘Standing Together’ with our Stakeholders at all times is our ingredient for Synergy and Growth.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Values