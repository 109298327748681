import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'

import fundtransfter from '../../img/moneytransfer/dmt-money-transfer.png'
import neardoor from '../../img/moneytransfer/dmt-doorstep.png'
import costeffective from '../../img/moneytransfer/dmt-cost-effective.png'
import safetransfer from '../../img/moneytransfer/dmt-safe-secure.png'

const MoneyTransfer = () => {
    const data = [
        {
            imageUrl: fundtransfter,
            title: 'Fund Transfer to all PSUs & Private Banks'
        },
        {
            imageUrl: neardoor,
            title: 'Near Doorstep Remittance Facility'
        },
        {
            imageUrl: costeffective,
            title: 'Cost Effective'
        },
        {
            imageUrl: safetransfer,
            title: 'Safe and Secure Transaction'
        },
        // {
        //     imageUrl: 'https://mobisafar.com/assets/images/dmt-free-sms-alerts.png',
        //     title: 'Free SMS Alerts on Every Transaction'
        // },
    ];

    const data2 = [
        {
            title: 'Direct Money Transfer (DMT) is a unique product  that can be used to send money instantly to any  Bank’s account holder within India. Through this  product any Indian citizen can walk in with cash-in- hand to any nearest Customer Service Point (CSP), fill in few details and have the money transferred to there known ones, anywhere in the country'
        },
      
    ];

    const bottomListItems = [
        'IIN (Identifying the Bank to which the customer is associated)',
        'Aadhaar Number',
        'Fingerprint captured during their enrollment'
    ];

    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginBottom: 40 }}>Domestic Money Transfer (DMT)</h2>
                <div className='row align-items-center'>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-3 col-6">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4>{item.title}</h4>
                        </div>
                    ))}
                </div>
                <h3 style={{ color: "#000", textAlign: "left", fontSize:16 }}>The  inputs required for a customer to do a transaction under this scenario are :-</h3>
                <ul className="bottom-list">
                    {bottomListItems.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex  gap-2' style={{ padding: "5px 0" }}>
                                <div>
                                    <img src={dot} style={{ width: 17, marginTop: 6 }}></img>
                                </div>
                                <div >
                                    <p>{item}</p>
                                </div>
                            </div>
                        </li>))}
                </ul>
                        
                <div className='loginlink'>
                    <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                </div>
            </div>
        </div>
    );
};

export default MoneyTransfer;
