import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';

const services = [
  { "title": "Bill Payment ", "content": require('./CspBillPayment.js').default },
  { "title": "Cash Collection", "content": require('./CspCashcollection.js').default },
  { "title": "Loan Repayment", "content": require('./CspLoanRepayment.js').default },
  { "title": "Insurance Premium", "content": require('./CspInsurancePremium.js').default },
  { "title": "Recharges", "content": require('./CspRecharges.js').default },
];

const data2 = [
  {
    title: 'Bring convenience to your customers by helping them pay their utility bills online in their neighborhood,relieving them from the hassles of standing in queues and managing their time. CSP helps you pay the following bills utilizing the Bharat Bill Payment systems which broadly includes electricity, Water, FastTag, Loan EMI, and LIC and other Insurance Renewal Premium'
  },
  {
    title: 'CSP brings to you Bharat Bill Payment System (BBPS), which is a RBI mandated system which offers integrated and interoperable bill payment services to customers across geographies with certainty, reliability and safety of transactions.'
  },
  {
    title: 'BBPS is growing at a substantial rate. The transactional value of BBPS has increased to a Year-on-Year (YoY) growth of 66% in FY 2022–23. With all categories now open for BBPS, the transactional volume is expected to grow by over four times in the next five years from 1.05 billion in FY 2022–23 to 5.4 billion in FY 2026–27.'
  },
  {
    title: 'Strategically, as a Grahak Pratinidhi of CSP, this is a critial enabler to help build customer engengment and relationship sell as customers from the surrounding locality will visit their counter regularly for all their utility payments'
  },
  
];
const CspPaymentServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div style={{ marginBottom: 54, }}>
          <h2 style={{ textAlign: "center", marginBottom: 20, fontWeight: 700, color: "#2C5363" }}>Scope and Offering</h2>
          <div className='aeps-all-content'>
            {data2.map((item, index) => (
              <div key={index}>
                <h4 style={{ color: "#000", }}>{item.title}</h4>
              </div>
            ))}
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspPaymentServices;
