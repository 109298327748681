import React from 'react'
import './Footer.css'

import { Link } from 'react-router-dom'
import Email from './email.png'
import Call from './call.png'
import Home from './location-pin.png'
import Expand from './down.png'

import ISOFooter from './iso2.png'

const Footer = () => {
    const ToTop = () => {
        window.scrollTo(0, 0);
    }
    return (
        <div className='footer'>
            <div className='footer-container'>
                <div className='row  position-relative'>
                    <div className='col-lg-3 col-md-3 py-1'>

                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>Company</h5>

                            <li>
                                <Link to='/About' onClick={() => ToTop()}>About Us</Link>
                            </li>
                            <li>
                                <Link to='/Careers' onClick={() => ToTop()}>Careers</Link>
                            </li>
                            <li>
                                <Link to='/Resources' onClick={() => ToTop()}>Resources</Link>
                            </li>
                            {/* <li>
                            <Link to='/Media&Events'>Media & Events</Link>
                        </li> */}
                            {/* <li>
                                <Link to='#'>Media & Events</Link>
                            </li> */}
                            <li>
                                <Link to='/Become-a-Partner' onClick={() => ToTop()}>Join Us </Link>
                            </li>
                            <li>
                                <Link to='/ContactUs' onClick={() => ToTop()}>Contact us</Link>
                            </li>

                        </div>


                        {/* small devices */}
                        <div className='small-devices-footer'>
                            <div className='pb-1 d-flex justify-content-between align-items-center' data-bs-toggle="collapse" href="#companyCollapse" role="button" aria-expanded="false" aria-controls="companyCollapse">
                                <h5 className='footer-head m-0'>Company</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="companyCollapse">
                                <li>
                                    <Link to='/About' onClick={() => ToTop()}>About Us</Link>
                                </li>
                                <li>
                                    <Link to='/Careers' onClick={() => ToTop()}>Careers</Link>
                                </li>
                                <li>
                                    <Link to='/Resources' onClick={() => ToTop()}>Resources</Link>
                                </li>
                                <li>
                                    <Link to='/Become-a-Partner' onClick={() => ToTop()}>Become a Partner</Link>
                                </li>
                                <li>
                                    <Link to='/ContactUs' onClick={() => ToTop()}>Contact with Us</Link>
                                </li>
                            </div>
                        </div>

                    </div>


                    <div className='col-lg-3 col-md-3 py-1'>

                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>Services</h5>
                            <li>
                                <a href='/banking'>Banking Services</a>
                            </li>
                            <li>
                                <a href='/payment'>Payment Services</a>
                            </li>
                            <li>
                                <a href='/financial'>Financial Services</a>
                            </li>
                            <li>
                                <a href='/investment'> Investment Services</a>
                            </li>
                            <li>
                                <a href='/travel'>Travel Services</a>
                            </li>
                            
                        </div>

                        <div className='small-devices-footer'>
                            <div className='pb-1 d-flex align-items-center justify-content-between' data-bs-toggle="collapse" href="#solutionCollapse" role="button" aria-expanded="false" aria-controls="solutionCollapse">
                                <h5 className='footer-head m-0'>Services</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="solutionCollapse">
                                <li>
                                    <a href='/Services#bankingservices' >Banking Services</a>
                                </li>
                                <li>
                                    <a href='/Services#paymentservices'>Payment Services</a>
                                </li>
                                <li>
                                    <a href='/Services#legalservices'>Legal Services</a>
                                </li>
                                <li>
                                    <a href='/Services#governmentservices'>Goverment Services</a>
                                </li>
                                <li>
                                    <a href='/Services#insuranceservices'>Insurance Services</a>
                                </li>
                                <li>
                                    <a href='/Services#financeservices'>Finance Services</a>
                                </li>
                            </div>
                        </div>

                    </div>


                    {/* <div className='col-lg-3 col-md-3 py-1'>

                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>Solution</h5>
                            <li>
                                <a href='Solutions#bankingsolution' className={"bankingsolution"}>Banking Solutions</a>
                            </li>
                            <li>
                                <a href='Solutions#paymentsolution' className={"paymentsolution"}>Payment Solutions</a>
                            </li>
                            <li>
                                <a href='Solutions#financialsolution' className={"financialsolution"}>Financial Solutions</a>
                            </li>
                            <li>
                                <a href='Solutions#legalsolution' className={"legalsolution"}>Legal Solutions</a>
                            </li>
                            <li>
                                <a href='Solutions#travelsolution' className={"travelsolution"}>Travel Solutions</a>
                            </li>
                            <li>
                                <a href='Solutions#insurancesolution' className={"insurancesolution"}>Insurance Solutions</a>
                            </li>
                        </div>

                        <div className='small-devices-footer'>
                            <div className='pb-1 d-flex align-items-center justify-content-between' data-bs-toggle="collapse" href="#solutionCollapse" role="button" aria-expanded="false" aria-controls="solutionCollapse">
                                <h5 className='footer-head m-0'>Solution</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="solutionCollapse">
                                <li>
                                    <a href='Solutions#bankingsolution' className={"bankingsolution"}>Banking Solutions</a>
                                </li>
                                <li>
                                    <a href='Solutions#paymentsolution' className={"paymentsolution"}>Payment Solutions</a>
                                </li>
                                <li>
                                    <a href='Solutions#financialsolution' className={"financialsolution"}>Financial Solutions</a>
                                </li>
                                <li>
                                    <a href='Solutions#legalsolution' className={"legalsolution"}>Legal Solutions</a>
                                </li>
                                <li>
                                    <a href='Solutions#travelsolution' className={"travelsolution"}>Travel Solutions</a>
                                </li>
                                <li>
                                    <a href='Solutions#insurancesolution' className={"insurancesolution"}>Insurance Solutions</a>
                                </li>
                            </div>
                        </div>

                    </div> */}


                    <div className='col-lg-3 col-md-3 py-1'>

                        <div className='large-device-footer'>
                            <h5 className='footer-head pb-3'>Important Links</h5>

                            <li>
                                <Link to='./PrivacyPolicy' onClick={() => ToTop()}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to='./Terms&Conditions' onClick={() => ToTop()}>Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to='./CookiePolicy' onClick={() => ToTop()}>Cookie Policy</Link>
                            </li>
                            <li>
                                <Link to='./Refund&Cancellation' onClick={() => ToTop()}>Refund & Cancellation</Link>
                            </li>
                            {/* <li>
                            <Link to='./AadharDataPolicy'>Aadhar Data Policy</Link>
                        </li> */}
                            <li>
                                <Link to='./Desclaimer' onClick={() => ToTop()}>Desclaimer</Link>
                            </li>
                        </div>

                        <div className='small-devices-footer'>
                            <div className='pb-3 d-flex justify-content-between align-items-center' data-bs-toggle="collapse" href="#impLinksCollapse" role="button" aria-expanded="false" aria-controls="impLinksCollapse">
                                <h5 className='footer-head m-0'>Important Links</h5>
                                <img className='expand-icon' src={Expand} alt='expand' />
                            </div>
                            <div className='collapse' id="impLinksCollapse">
                                <li>
                                    <Link to='./PrivacyPolicy' onClick={() => ToTop()}>Privacy Policy</Link>
                                </li>
                                <li>
                                    <Link to='./Terms&Conditions' onClick={() => ToTop()}>Terms & Conditions</Link>
                                </li>
                                <li>
                                    <Link to='./CookiePolicy' onClick={() => ToTop()}>Cookie Policy</Link>
                                </li>
                                <li>
                                    <Link to='./Refund&Cancellation' onClick={() => ToTop()}>Refund & Cancellation</Link>
                                </li>
                                <li>
                                    <Link to='./Desclaimer' onClick={() => ToTop()}>Desclaimer</Link>
                                </li>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-3 py-1'>
                        <h5 className='footer-head pb-3'>Reach Us</h5>
                        <div className='reach'>
                            <li className='d-flex'>
                                <div className=' px-2'>
                                    <img src={Home} alt='address' />
                                </div>
                                <Link className='text-start'>
                                    Corp.Off 207, City Avenue by Kolte Patil, near Range Rover ShowRoom, Wakad. 
                                </Link>
                                {/* <Link>CSP INDIA NETWORK PVT. LTD.<br />C/O SURESH DAULAT PATIL, J.D.C.C. BANK COLONY, PACHORA, Jalgaon, Maharashtra, India, 424201</Link> */}
                            </li>
                            <li className='d-flex align-items-center'>
                                <div className='py-2 px-2'>
                                    <img src={Call} alt='whatsapp' />
                                </div>
                                <Link>020-711-72777</Link>
                            </li>
                            <li className='d-flex align-items-center'>
                                <div className='py-2 px-2'>
                                    <img src={Email} alt='whatsapp' />
                                </div>
                                <Link>
                                    support@csp.in
                                </Link>
                            </li>
                            {/* <li>
                                <div className='mt-3 footer-icons'>
                                    <img src={Facebook} alt='footer-icons' />
                                    <img src={Linkdin} alt='footer-icons' />
                                    <img src={Twitter} alt='footer-icons' />
                                    <img src={Instagram} alt='footer-icons' />
                                </div>
                            </li> */}

                        </div>
                    </div>

                    {/* <img src={ISOFooter} className='iso-img-footer-2 position-absolute' alt='iso' /> */}
                </div>

                <div className='bottom-footer py-3 mt-1 d-flex justify-content-center align-items-center'>
                    Disclaimer : Customer Service Point ( CSP) is a digital offering by CSP India Network Private Limited bearing CIN No.U72900MH2022PTC385867. <br />*Earning refers to the commission earned by our partners, subject to the nature of service and agreed terms and conditions.<br/>*Instant Commision is applicable on Select Services
                </div>

            </div>
            <div className='footer-copyright'>
                <div className='footer-container align-items-center'>
                    <div className='large-device-footer-copyright'>
                        <div className='d-flex justify-content-between'>
                            <span>
                                Copyright&nbsp;<i className='fa fa-copyright p'></i>&nbsp; CSP INDIA NETWORK PRIVATE LIMITED. All Rights Reserved.
                            </span>
                            <span>
                                A  Startup India Certified Company.
                            </span>
                        </div>
                    </div>

                    <div className='small-device-footer-copyright'>
                        <span>
                            Copyright&nbsp;<i className='fa fa-copyright p'></i>&nbsp;2023 CSP INDIA NETWORK PRIVATE LIMITED. All Rights Reserved.
                        </span>
                        <span>
                        A  Startup India Certified Company.
                        </span>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer