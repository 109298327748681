import React from 'react'
import './Banking.css'

const Bankingsub = () => {
    return (
        <div className='banking-sub-container'>
            <div className='banking-sub-main-container'>
                <div className='row text-center d-flex justify-content-center icon-services-container pb-3 pt-1 '>

                    <div className='col-lg-3 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-fingerprint d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            AEPS
                        </p>
                    </div>

                    <div className='col-lg-3 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-tap d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Aadhar Pay
                        </p>
                    </div>

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-rupee d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Money Transfer
                        </p>
                    </div> */}

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-pos-terminal d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            MATM
                        </p>
                    </div>

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-credit-card d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Credit Card
                        </p>
                    </div> */}

                    <div className='col-lg-3 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='bank-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-passbook d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Account Opening
                        </p>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default Bankingsub