import React from 'react'
import './Legal.css'
import LegalImg from '../Assets/legal.jpg'
import Legalsub from './Legalsub'

const Legal = () => {
    return (
        <>
            <a href='Services#legal' className="legal" style={{
                textIndent:'100%',
                whiteSpace:'nowrap',
                overflow:'hidden',
                visibility:'hidden'
            }}>Legal</a>
            <div className='legal-container' id='legal'>
                <div className='legal-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center p-0'>
                            <div className='legal-head'>
                                <h1>
                                    Legal
                                </h1>
                                <h5>
                                Legal and Accounting - Help  your customers by providing them access to various legal and financial services from documentation to consultations like Affidavit formats, Forms, ITR filing, GST filing etc. at pocket friendly rates

                                </h5>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center services-side-img'>
                            <div className='text-center'>
                                <img className='w-100' src={LegalImg} alt='Legal' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Legalsub />
            </div>
        </>
    )
}

export default Legal