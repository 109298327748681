import React from 'react'
import './Legal.css'

const Legalsub = () => {



    return (
        <div className='legal-sub-container'>
            <div className='legal-sub-main-container'>
                {/* <h2 className='text-white text-center'>Legal Services</h2> */}
                <div className='row text-center icon-services-container pb-3 pt-1'>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-tax d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            GST <br /> & Income Tax
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-boost d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Startup<br />  Business
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-idea d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Intellectual<br />  Property
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-certificate d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Licenses <br /> & Registrations
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-accounting d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Accounting <br /> & Compliances
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='legal-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-file d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Affidavit <br /> & Agreement
                        </p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Legalsub