import * as React from "react"
const LeftArrow = (props) => {
    const { color } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            width={20}
            height={20}
            viewBox="0 0 32 32"
            {...props}
        >
            <path
                d="M6 16h22M11.515 22l-6-6 6-6"
                style={{
                    fill: "none",
                    stroke: color ? color : "#2c5363",
                    strokeWidth: 1.5,
                    strokeMiterlimit: 10,
                }}
            />
        </svg>
    )
}
export default LeftArrow