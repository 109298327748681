import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'


const Bbps = () => {
  

    const ServicesAeps = [
        {
            title: 'Train Booking',
            paragraph: 'Help your customers bypass queues for Ticket booking by bringing them the convenience of booking. We bring this to you in partnership with our IRCTC approved partner.'
        },
    ];

    const bottomListItems = [
        {
            title: '   What is Aadhaar?',
            paragraph: 'Aadhaar is an unique number that is assigned to residents of India by the Unique Identification Authority of India (UIDAI).'
        },
        {
            title: 'What is Aadhaar Enabled Payment System?',
            paragraph: ' Aadhaar Enabled Payment System is a payment service empowering a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled bank account and perform basic banking transactions like balance enquiry, cash deposit, cash withdrawal, remittances through any BC counter ( As per NPCI)'
        },
        {
            title: 'Who can use AEPS?',
            paragraph: 'All Indian residents holding a valid Aadhaar card and a bank account can use this payments service.'
        },
        {
            title: 'How can a user enable this service?',
            paragraph: 'To start using AEPS, first link your bank account with your Aadhaar number. Then, contact your bank and let them know that you want to start using AEPS.'
        },
        {
            title: 'Should the payee/beneficiary also have AEPS to receive funds?',
            paragraph: 'It is not necessary that the payee or beneficiary has AePS to receive funds'
        },
        {
            title: 'Does the customer specifically need to register with his Bank to be able to transact through AEPS?',
            paragraph: 'Ideally No, but linking Aadhaar Number with Bank account is a pre-requisite. However, registration process shall be as per the procedures laid down by the Issuer bank providing the service. Please check from the bank where you hold the Aadhaar linked bank account.'
        },
    ];


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Frequently Asked Questions</h1>
                <ul className="bottom-list">
                    {bottomListItems.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div>
                                <div>
                                    <p><strong>{item.title}</strong></p>
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>


            </div>
        </div>
    );
};

export default Bbps;
