import React from 'react'
import { Link } from 'react-router-dom'
import './BlogCards.css'
import Blog1Img from '../Assets/impactd.jpg'
import BLog2Img from '../Assets/transformation.jpg'
import Blog3Img from '../Assets/aeps.jpg'



const BlogCards = () => {
    return (
        <div className='resourses-container p-60'>
            <div className='blog-card-container'>
                <div className='knowledge-main-container'>
                    <div className='row'>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center'>
                            <div className='blog-cards mt-4 shadow-sm px-4 py-3 position-relative'>
                                <Link to="/Digital_Transformation">
                                    <div className='o_h_class'>
                                        <img className='blogs-cards-imgs' src={Blog1Img} alt='blog-slider' />
                                    </div>
                                </Link>
                                <Link to="/Digital_Transformation"><h5 className='mt-3'>Digital Transformation</h5></Link>
                                <Link to="/Digital_Transformation"><p>We all have witnessed and acknowledged the power of the ‘Digital’ medium. One can undoubtedly say that digital is the buzzword in today’s world. The importance of going digital and its boon was very closely experienced by everyone at the time of the COVID pandemic.</p></Link>
                                <div className='fixed-bottom position-absolute d-flex align-items-center justify-content-between pb-3 px-3' style={{zIndex:0}}>
                                    <Link to='/Digital_Transformation'>
                                        <button className='btn d-flex align-items-center py-2 px-3 mt-3'>Read More |
                                            <span className='blog-span'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="currentColor" class="bi bi-arrow-right transition-ease-in-out" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </Link>
                                    <Link to='/Digital_Transformation'><span class="badge rounded-pill text-dark mt-3">Blog</span></Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center'>
                            <div className='blog-cards mt-4 shadow-sm px-4 py-3 position-relative'>
                                <Link to="/Impact_of_Digital_Transformation">
                                    <div className='o_h_class'>
                                        <img className='blogs-cards-imgs' src={BLog2Img} alt='blog-slider' />
                                    </div>
                                </Link>
                                <Link to="/Impact_of_Digital_Transformation"><h5 className='mt-3'>Impact of Digital Transformation in India</h5></Link>
                                <Link to="/Impact_of_Digital_Transformation"><p>On the 75th year of our Independence, India is not only the largest and one of the most progressive democracy in this world but also one of the fastest-growing major economies, expanding at a rate almost three times the world average and on track to become a US$5 trillion economy before the end of the decade. We must acknowledge the role of multiple technological innovations which has been a key facilitator to this.</p></Link>
                                <div className='fixed-bottom position-absolute d-flex align-items-center justify-content-between pb-3 px-3' style={{zIndex:0}}>
                                    <Link to='/Impact_of_Digital_Transformation'>
                                        <button className='btn d-flex align-items-center mt-3'>Read More |
                                            <span className='blog-span'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="currentColor" class="bi bi-arrow-right transition-ease-in-out" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </Link>
                                    <Link to="/Impact_of_Digital_Transformation"><span class="badge rounded-pill text-dark mt-3">Blog</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12 col-12 d-flex justify-content-center'>
                            <div className='blog-cards mt-4 shadow-sm px-4 py-3 position-relative'>
                                <Link to="/AEPS_Blog">
                                    <div className='o_h_class'>
                                        <img className='blogs-cards-imgs' src={Blog3Img} alt='blog-slider' />
                                    </div>
                                </Link>
                                <Link to="/AEPS_Blog"><h5 className='mt-3'>Aadhaar Enabled Payment System</h5></Link>
                                <Link to="/AEPS_Blog"><p>Convenience’ has always been a very comforting word to every individual and no one deny availing the same. Well, most of us have experienced and discussed convenience related to many facets of our lives but, we would unanimously agree that most recently the attribute of convenience has been attached to something that’s quite unconventional in nature and has bought a paradigm shift in the related industry, yes you might have almost guessed it by now – ‘the mode of digital payment ‘ in the financial and banking industry.</p></Link>
                                <div className='fixed-bottom position-absolute d-flex align-items-center justify-content-between pb-3 px-3' style={{zIndex:0}}>
                                    <Link to='/AEPS_Blog'>
                                        <button className='btn d-flex align-items-center py-2 px-3 mt-3'>Read More |
                                            <span className='blog-span'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="20" fill="currentColor" class="bi bi-arrow-right transition-ease-in-out" viewBox="0 0 16 16">
                                                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
                                                </svg>
                                            </span>
                                        </button>
                                    </Link>
                                    <Link to="/AEPS_Blog"> <span class="badge rounded-pill text-dark mt-3">Blog</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogCards