import CspTravelhome from "./CspTravelHome"
import CspTravelServices from "./CspTravelServices/CspTravelServices"
const CspTravel = () => {
    return (
        <div className="about">
            <CspTravelhome />
            <CspTravelServices />
        </div>
    )
}
export default CspTravel