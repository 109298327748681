import React from 'react'
import './Financial.css'

const Financialsub = () => {



    return (
        <div className='financial-sub-container'>
            <div className='financial-sub-main-container'>
                <div className='row text-center icon-services-container d-flex justify-content-center pb-3 pt-1'>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-money-bag d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Digital Gold
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-content-management-system d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Buisness Loan
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-trend d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            MSME Loan
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-gold d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Working Capital Loan
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-credit-card-2 d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Loan Against MF
                        </p>
                    </div>

                    {/* <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='financial-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-gift-card d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Gift Card
                        </p>
                    </div> */}

                </div>
            </div>
        </div>
    )
}

export default Financialsub