import React, { useEffect } from 'react'
import './OurSolutions.css'
import BankSolution from '../Assets/banking-solution.jpg'
import FinancialSol from '../Assets/corporate-solutions.png'
import LegalSol from '../Assets/legal-solu.jpg'
import PaymentSol from '../Assets/payment-solu.jpg'
import Travelsol from '../Assets/travel-solution.jpg'
import InsuranceSol from '../Assets/insurance-solu.jpg'
import { useLocation } from 'react-router-dom';
const OurSolutions = () => {
    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);


    return (
        <div className='ourSolutions-container'>
            <div className='ourSolutions-main-container'>
                <div className='d-flex justify-content-center'>
                    <h2 className='mb-4'>Our Solutions</h2>
                </div>


                {/* Banking */}
                <a id='bankingsolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1 p-0'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Banking Solutions</h5>
                            <p className='text-justify'>
                                CSP has enabled its digital platform with an array of banking services to help its partners to cater to the day to day needs of their customers basic banking requirements
                                {/* Banking solution refers to the various technological tools, systems, and services that enable banks and other financial institutions to provide a range of banking services to their customers. Banking solutions are designed to enhance the efficiency, convenience, and security of banking operations, and to provide customers with access to a wide range of financial products and services. */}
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2 p-0'>
                        <div className=' py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={BankSolution} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle'></div>
                        </div>
                    </div>
                </div>



                {/* Payment */}
                <a name='paymentsolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5 solution-reverse-column'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                        <div className='left-side py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={PaymentSol} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle left-circle'></div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Payments Solutions</h5>
                            <p className='text-justify'> CSP in alignment with Bharat Bill Payment systems brings various utility bill payments services like water, electricity, gas, broadband, Fast tag, Municipal corporation Tax, Lic premium etc. These services can be availed by the customer through CSP associates.
                            </p>
                        </div>
                    </div>
                </div>


                {/* Financial */}
                <a name='financialsolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Financial Solutions</h5>
                            <p className='text-justify'>
                                CSP facilitates empowerment by bringing to its Individual customers as well as business entities multiple loan options through various financial institutions. This facility can be availed by the customers through the network of CSP associates
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                        <div className=' py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={FinancialSol} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle'></div>
                        </div>
                    </div>
                </div>



                {/* Travel */}
                <a name='travelsolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5 solution-reverse-column'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                        <div className='left-side py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={Travelsol} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle left-circle'></div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Travel Solutions</h5>
                            <p className='text-justify'>
                                Our associates can help their customers ease their ticketing hassles by bringing them online booking facility for Trains and Buses. CSP brings you an access to IRCTC agents Log in.
                            </p>
                        </div>
                    </div>
                </div>


                {/* ************************ Loan */}

                {/* <a name='loansolution' href='/'><b></b></a>
                    <div className='row justify-content-between justify-content-lg-between mb-5'>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                            <div className='w-75'>
                                <h5 className=''>Loan Solutions</h5>
                                <p className='text-justify'>
                                    CSP facilitates empowerment by bringing to its Individual customers as well as business entities multiple loan options through various financial institutions. This facility can be availed by the customers through the network of CSP associates                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                            <div className=' py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                                <img className='' src={LoanSol} alt='bank-solutions' />
                                <div className='position-absolute rounded-circle'></div>
                            </div>
                        </div>
                    </div> */}


                {/* <a name='govermentsolution' href='/'><b></b></a>
                    <div className='row justify-content-between justify-content-lg-between mb-5'>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                            <div className='w-75'>
                                <h5 className=''>Goverment Solutions</h5>
                                <p className='text-justify'>
                                    CSP Platform offers access to multiple citizen services initiated the Government. These include Pan Card, Voter ID, Jeevan Praman etc
                                </p>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                            <div className=' py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                                <img className='' src={GovermentSol} alt='bank-solutions' />
                                <div className='position-absolute rounded-circle'></div>
                            </div>
                        </div>
                    </div> */}


                {/* Legal */}
                <a name='legalsolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                        <div className='left-side py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={LegalSol} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle '></div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Legal Solutions</h5>
                            <p className='text-justify'>
                                CSP Platform offers access to multiple Legal services initiated the Government. These include GST, Income Tax, Startup, Legal Drafting, Affidavit, Agreement etc
                            </p>
                        </div>
                    </div>
                </div>



                {/* Insurance */}
                <a name='insurancesolution' href='/'><b></b></a>
                <div className='row justify-content-between justify-content-lg-between mb-5 solution-reverse-column'>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-2'>
                        <div className='solutions-head-content'>
                            <h5 className=''>Insurance Solutions</h5>
                            <p className='text-justify'>
                                Protection through insurance has become an essential in recent times. CSP through its insurance partners strives to bring various offerings for our customers to help adequately fulfill their insurance requirements
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-center order-1'>
                        <div className='left-side py-5 position-relative d-flex align-items-center justify-content-center w-75'>
                            <img className='' src={InsuranceSol} alt='bank-solutions' />
                            <div className='position-absolute rounded-circle left-circle'></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default OurSolutions