import React from 'react'
import TravelHome from './TravelHome/TravelHome'
import WhatisTravel from './WhatisTravel/WhatisTravel'
import TravelReq from './TravelReq/TravelReq'
import TravelSegment from './TravelSegment/TravelSegment'




const NewTravel = () => {
    return (
        <div className='about'>
            <TravelHome />
            <WhatisTravel />
            <TravelReq />
            <TravelSegment />
        </div>
    )
}

export default NewTravel