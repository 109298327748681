import React from 'react'
import './Financial.css'
import FinancialImg from '../Assets/financial.jpg'
import Financialsub from './Financialsub'

const Financial = () => {
    return (
        <>
            <div className='financial-container' id='financial'>
                <div className='financial-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center p-0'>
                            <div className='financial-head'>
                                <h1>
                                   Investment & Finance
                                </h1>
                                <h5>
                                Investment and Finance are two key necessities in the current times. CSP brings to you the prudent choices in both.
                                </h5>
                                <h5>Gold has been our traditional investment option and now with the option of Digital Gold, this has both security as well as flexibility in terms of purchasing and selling.</h5>
                                <h5>In this era of growing business set ups, speedy fulfilment of financial requirements is critical.We bring to you digital offerings of Business Loan, MSME Loan working Capital Loan and Loan against Mutual Fund</h5>

                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center services-side-img'>
                            <div className='text-center'>
                                <img className='w-100' src={FinancialImg} alt='financial' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Financialsub />
            </div>
        </>
    )
}

export default Financial