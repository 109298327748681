import './RoeSection.css'
import img1 from '../../../Components/Landing/FifthSection/Images/Fingerprint.svg'
import img2 from '../../../Components/Landing/FifthSection/Images/BBPS.svg'
import img3 from '../../../Components/Landing/FifthSection/Images/GigitalGold.svg'
import img4 from '../../../Components/Landing/FifthSection/Images/Lending.svg'
import img5 from '../../../Components/Landing/FifthSection/Images/Ticket.svg'

const RoeSection = () => {
    const data = [
        {
            id: 1,
            image: img1,
            title: 'AePS and DMT',
            text: 'Last mile Banking avenue, has a potential to reach to over 80% of rural households'
        },
        {
            id: 2,
            image: img2,
            title: 'BBPS',
            text: 'Overall Transactions projected to grow to INR 5.4 Billion by 2027'
        },
        {
            id: 3,
            image: img3,
            title: 'Digital Gold',
            text: 'Projected to become INR 10k Crore market opportunity by 2025'
        },
        {
            id: 4,
            image: img4,
            title: 'Digital Lending',
            text: 'Disbursement projected at INR 47.4 Lakh crore by 2026'
        },
        {
            id: 5,
            image: img5,
            title: 'Online Ticketing',
            text: '80% of Rail tickets are booked online, Non metro regions contribute to 67% online Bus ticket booking'
        },

    ];
    return (
        <div className='' style={{ backgroundColor: '#2c5363', paddingTop: 80, paddingBottom: 50 }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <div className="roesection-content">
                            <h2 style={{marginBottom:14}}>Maximize the Return on Your Efforts <span>(ROE)</span> : Invest yourself in Avenues with High Growth Potential</h2>
                            {/* <h2>Growing Business avenues to help you maximize your Return on your efforts <span>(ROE)</span> </h2> */}
                            <p>As India aspires to become the 3rd largest economy in the world. Rural and Semi Urban India constituting of 65% of the total population and 47% of the National GDP is all set to play a pivotal role. CSP brings to you offerings from avenues which are poised for a significant growth in this geography.</p>

                        </div>
                    </div>

                    <div className='col-lg-8'>
                        <div className='roe-column2'>
                            <div className="roesection-content2">
                                <h2>Offerings that Ensure your Business Growth</h2>
                            </div>
                            {data.map(item => (
                                <div className='row align-items-center' >
                                    <div className='col-lg-5'>
                                        <div className='roe-col2-content'>
                                            <img src={item.image} style={{ width: 40 }}></img>
                                            <h2>{item.title}</h2>
                                        </div>
                                    </div>
                                    <div className='col-lg-7'>
                                        <div className='roe-col2-content'>
                                            <p>{item.text}</p>
                                        </div>
                                    </div>
                                    <img src='https://signdesk.com/images/about-us/green_underline.png'></img>
                                </div>

                            ))}
                        </div>
                    </div>

                </div>


            </div>
        </div >
    )
}
export default RoeSection