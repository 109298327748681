import React, { useEffect } from 'react'
import './Payments.css'
import PaymentImg from '../Assets/payment.jpg'
import Paymentssub from './Paymentssub'
import { useLocation } from 'react-router-dom';

const Payments = () => {

    const location = useLocation();
    useEffect(() => {
        if (location.hash !== null) {
            let a = location.hash.toString().substr(1, location.hash.length);
            let dos = document.getElementsByClassName(a);
            if (dos.length > 0) {
                dos[0].click();
            }
        }
    }, [location]);

    return (
        <>
            <div className='payment-container' id='payment' >
                <div className='payment-main-container'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 col-12 d-flex justify-content-cente align-items-center p-0'>
                            <div className='pay-head'>
                                <h1>
                                    Payments
                                </h1>
                                <h5>
                                Service your customers by helping them with hassle free utility and bill payments services like water, electricity, gas, broadband, Fast tag, Municipal corporation Tax etc. Availability of CMS services like Loan EMI payment and Insurance renewal payment etc. helps to add on convenience 
                                </h5>

                            </div>
                        </div>
                        <div className='services-side-img col-lg-6 col-md-6 col-sm-12 col-12 d-flex align-items-center justify-content-center'>
                            <div className='text-center'>
                                <img className='w-100' src={PaymentImg} alt='payment' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Paymentssub />
            </div>
            <a href='Services#payment' className={"payment"} style={{
                textIndent:'100%',
                whiteSpace:'nowrap',
                visibility:'hidden'
            }}>Payment</a>
        </>
    )
}

export default Payments