import React from 'react'
import './Media.css'
import MediaCards from './MediaCards'

const Media = () => {
    return (
        <>
            <div className='media-container'>
                <div className='media-head'>
                    <h3 className='text-center my-0'>Lorem Ipsum Lorem</h3>
                </div>
            </div>
            <MediaCards />
        </>
    )
}

export default Media