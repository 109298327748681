import React from 'react'
import './Cookie.css'

const Cookie = () => {
    return (
        <div className='cookie-container'>
            <div className='cookie row'>
                <h1 className='text-center'>
                    Cookie Policy
                </h1>
                <p>This Cookie Policy describes the different types of Cookies that may be applied by M/s CSP INDIA NETWORK PVT LTD on its platform. We may change this Cookie Policy at any time in order to reflect, for example, changes to the Cookies we use or for other operational, legal or regulatory reasons.</p>

                <p>If you have questions regarding this Cookie Policy, please contact us at
                C/O SURESH DAULAT PATIL,, J.D.C.C. BANK COLONY, PACHORA, Jalgaon, Maharashtra, India, 424201</p>

                <p>The word Platform shall mean and include the website www.csp.in</p>

                <b>What is a Cookie?</b>

                <p>A Cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of Cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser Cookies, or to alert you when Platforms set or access Cookies. If you disable or refuse Cookies, please note that some parts of this Platform may become inaccessible or not function properly.</p>

                <b>What Cookies do we use?</b>
                <ol className='row'>
                    <li><strong>Strictly necessary Cookies:</strong> These are Cookies that are required for the operation of our Platform. They include, for example, Cookies that enable you to log into secure areas of our Platform, use a shopping cart or make use of e-billing services.</li>

                    <li><strong>Analytical/performance Cookies:</strong> They allow us to recognize and count the number of visitors and to see how visitors move around our Platform when they are using it. This helps us to improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.</li>

                    <li><strong>Functionality Cookies:</strong> These are used to recognize you when you return to our Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>

                    <li><strong>Targeting Cookies:</strong> These Cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third-parties for this purpose.</li>
                </ol>

                <p>Please note that third-parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use Cookies, over which we have no control. These Cookies are likely to be analytical/performance Cookies or targeting Cookies. You can block Cookies by activating the setting on your browser that allows you to refuse the setting of all or some Cookies. However, if you use your browser settings to block all Cookies (including essential Cookies) you may not be able to access all or parts of our Platform.</p>

                <b>GOOGLE ANALYTICS</b>

                <p>We use Google Analytics to help us to understand how you make use of our content and work out how we can make things better. These Cookies may follow all visitors of the Platform and collect anonymous data on where they have come from, which pages they visit, and how long they spend on the site. This data is then stored by Google to create reports. These Cookies do not store personal data or business data. The information generated by the Cookie about the use of the Platform, including IP address, may be transmitted to, and stored by Google on servers in the United States. Google may use this information to evaluate the visitor’s use of the Platform, compiling reports on Platform activity for us and providing other services relating to Platform activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google will not associate any IP address’ with any other data held by Google. By using this Platform, all visitor’s consent to the processing of data by Google in the manner and for the purposes set out above. The Google Platform contains further information about Analytics and a copy of Google’s privacy policy pages.</p>

                <b>How do I manage Cookies?</b>

                <p>You can choose whether to accept our Cookies (except Strictly Necessary Cookies) by clicking on the appropriate opt-out links in our Cookie Preference Center, which can be found in our Site’s footer and Cookie banner. You also have the right to refuse or accept Cookies at any time by activating settings on your browser. You may wish to refer to <strong>http://www.getanyvoice.com/manage-Cookies/index.html</strong> for information on commonly used browsers.</p>

                <p>Note that blocking some types of Cookies may impact your experience on our Site and the services we are able to offer you.</p>
            </div>
        </div>
    )
}

export default Cookie