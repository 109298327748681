import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'

const CspLoanRepayment = () => {


    const data2 = [
        {
            title: 'CSP, through BBPS on its platform, brings to you the facility of Insurance Premium repayment. Help your customers pay their Loan EMI on time and without any hassle. Since most of the EMI’s are monthly in nature this will help in building a long term relationship with your customer and provide you an opportunity to offer to them various products and services'
        },

    ];

    const bottomListItems = [
        'Lorem ipsum dolor sit amet, consectetur',
        'Lorem ipsum dolor sit ',
        'Lorem ipsum dolor sit amet, consectetur',
        'Lorem ipsum dolor sit , consectetur',
        'Lorem ipsum ',
        'Lorem ipsum dolor sit amet, '
    ];
    const Objectives = [
        'To empower a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled bank account and perform basic banking transactions like cash deposit, cash withdrawal, Intrabank or interbank fund transfer, balance enquiry and obtain a mini statement through a Business Correspondent',
        'To sub-serve the goal of Government of India (GoI) and Reserve Bank of India (RBI) in furthering Financial Inclusion.',
        'To sub-serve the goal of RBI in electronification of retail payments',
        'To enable banks to route the Aadhaar initiated interbank transactions through a central switching and clearing agency.',
        'To facilitate disbursements of Government entitlements like NREGA, Social Security pension, Handicapped Old Age Pension etc. of any Central or State Government bodies, using Aadhaar and authentication thereof as supported by UIDAI.',
        'To facilitate inter-operability across banks in a safe and secured manner.',
        'To build the foundation for a full range of Aadhaar enabled Banking services.'
    ];
    const ServicesAeps = [
        'Cash Withdrawal',
        'Cash Deposit',
        'Balance Enquiry',
        'Aadhaar to Aadhaar Fund Transfer',
        'Mini Statement',
        'Best Finger Detection'
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Loan Repayment</h1>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000" }}>{item.title}</h4>
                        </div>
                    ))}
                    <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default CspLoanRepayment;
