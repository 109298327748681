import * as React from "react"
const RightArrow = (props) => {
  const { color, stroke } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={20}
      height={20}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        d="M26 16H4M20.485 10l6 6-6 6"
        style={{
          fill: "none",
          stroke: color ? color : "#2c5363",
          strokeWidth: stroke ? stroke : 1.5,
          strokeMiterlimit: 10,
        }}
      />
    </svg>
  )
}
export default RightArrow