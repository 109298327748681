import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'

const AccountOpening = () => {
    // const data = [
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-cash-deposit.png',
    //         title: 'Cash Deposit'
    //     },
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-cash-withdrawal.png',
    //         title: 'Cash Withdrawal'
    //     },
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-balance-enquiry.png',
    //         title: 'Balance Enquiry'
    //     },
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-mini-statement.png',
    //         title: 'Mini Statement'
    //     },
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-aadhaar-to-aadhaar.png',
    //         title: 'Aadhaar to Aadhaar Fund Transfer'
    //     },
    //     {
    //         imageUrl: 'https://mobisafar.com/assets/images/aeps-finger-detection.png',
    //         title: 'Best Finger Detection'
    //     },
    // ];

    const data2 = [
        {
            title: 'Help your customers to open savings account online with various banks at competitive interest rates. We offer different types of savings account with seamless banking experience.'
        },

    ];


    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginBottom: 40 }}>Account Opening</h2>
                {/* <div className='row align-items-center'>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-2 col-4">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div> */}
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4>{item.title}</h4>
                        </div>
                    ))}
                </div>
                <div className='loginlink'>
                    <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                </div>
                {/* <h3 style={{ color: "#000", textAlign: "left" }}>Process For Account Opening</h3>  */}

            </div>
        </div>
    );
};

export default AccountOpening;
