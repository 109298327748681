import React from 'react'
import './Payments.css'

function Paymentsub() {

    return (
        <div className='payment-sub-container'>
            <div className='payment-sub-main-container icon-services-container'>
                <div className='row text-center pb-3 pt-1'>
                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-cellphone d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Mobile Recharge
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-satellite-dish d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            DTH Recharge
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-electricity d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Electricity Bill
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-telephone d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Landline
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-router d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            Broadband
                        </p>
                    </div>

                    <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center' data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <div className='payment-services d-flex justify-content-center align-items-center'>
                            <i class="flaticon flaticon-app d-flex justify-content-center align-items-center"></i>
                        </div>
                        <p>
                            See More
                        </p>
                    </div>
                </div>

                {/* second row */}
                <div className='collapse' id="collapseExample">

                    <div className='row text-center'>
                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-cash d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Loan Repayment
                            </p>
                        </div>

                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-credit-card-1 d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Insurance Premium
                            </p>
                        </div>

                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-government d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Municiple Payment
                            </p>
                        </div>

                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-drop d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Water Billl
                            </p>
                        </div>

                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-stamp d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Challan
                            </p>
                        </div>

                        <div className='col-lg-2 col-md-4 col-sm-4 col-4 d-flex flex-column justify-content-center align-items-center'>
                            <div className='payment-services d-flex justify-content-center align-items-center'>
                                <i class="flaticon flaticon-mortarboard d-flex justify-content-center align-items-center"></i>
                            </div>
                            <p>
                                Education Fees
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Paymentsub