import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import realtimecomm from './img/train/rt-realtime-commision.png'
import railid from './img/train/rt-railway-id.png'
import freeirctcid from './img/train/rt-irctc.png'
import support from './img/train/rt-dedicated-support.png'
import lowprice from './img/train/rt-lowest-price.png'

const CspTrainBook = () => {
    const data = [
        {
            imageUrl: realtimecomm,
            title: 'Realtime Commissions'
        },
        {
            imageUrl: railid,
            title: 'Railway Authorized ID'
        },
        {
            imageUrl: freeirctcid,
            title: 'Free* Annual IRCTC ID'
        },
        {
            imageUrl: support,
            title: 'Dedicated Support'
        },
        {
            imageUrl: lowprice,
            title: 'Lowest Price'
        },
    ];




    const ServicesAeps = [
        {
            title: 'Train Booking',
            paragraph: 'Online train booking in India has transformed the way people plan their journeys, offering unparalleled convenience and flexibility. An effortless search for train routes, check seat availability, and booking tickets is ensured in an online experience. With secure payment options and instant confirmation, the hassle of standing in long queues at railway stations is eliminated. Additionally, online booking platforms often provide valuable information such as train schedules, seat layouts, and fare comparisons, empowering travelers to make informed decisions. Whether embarking on a short commute or a cross-country adventure, the ease and efficiency of online train booking have made it an essential tool for modern travelers across India.'
        },
        {
            title: 'Train Booking',
            paragraph: 'CSP brings to you online train ticketing through IRCTC, offer to your customers online train ticketing and add to their convenience'
        },
    ];


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Train Ticket Booking</h1>
                <div className='row '>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-2 col-4  col-content">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>

                <ul className="bottom-list">
                    {ServicesAeps.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                    <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspTrainBook;
