import DownArrow from "./DownArrow";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import Thumb from "./Thumb";

export const CustomSvg = {
    RightArrow,
    LeftArrow,
    DownArrow,
    Thumb
}