import React, { useState, useEffect } from 'react';
import '../../Banking/CspBankingServices/CspBankingServices.css';

const services = [
  { "title": "AePS", "content": require('../../Banking/CspBankingServices/InnerServices/Aeps.js').default },
  { "title": "Money Transfer", "content": require('../../Banking/CspBankingServices/InnerServices/MoneyTransfer.js').default },
  { "title": "QR ATM", "content": require('../../Banking/CspBankingServices/InnerServices/QrAtmService.js').default },
  { "title": "Micro ATM", "content": require('../../Banking/CspBankingServices/InnerServices/MicroAtm.js').default },
  { "title": "Account Opening", "content": require('../../Banking/CspBankingServices/InnerServices/AccountOpening.js').default },
];

const CspEgovServices = () => {
  const [selectedService, setSelectedService] = useState(null);
  const [contentVisible, setContentVisible] = useState(false);

  useEffect(() => {
    setSelectedService(services[0].title);
    setContentVisible(true);
  }, []);

  const handleServiceClick = (title) => {
    setContentVisible(false);
    setTimeout(() => {
      setSelectedService(title);
      setContentVisible(true);
    }, 300);
  };

  const ServiceContent = selectedService ? services.find(service => service.title === selectedService).content : null;

  return (
    <div className="cspbankingservices">
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 csp-col-1 col-md-4'>
            {services.map((item) => (
              <div key={item.title} className={`cspbankingservicescontent ${selectedService === item.title ? 'active' : ''}`} onClick={() => handleServiceClick(item.title)}>
                <h2>{item.title}</h2>
              </div>
            ))}
          </div>
          <div className='col-lg-8 csp-col-2 col-md-8'>
            <div className={`csp-content-container ${contentVisible ? '' : 'hidden'}`}>
              {selectedService && ServiceContent && <ServiceContent />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CspEgovServices;
