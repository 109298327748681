import React from 'react';
import './InnerServices.css';
import dot from './dot-circle-svgrepo-com.svg'
import qrcsp from './qr-csp.png' 


const QrAtmService = () => {
    const data = [
        {
            imageUrl: 'https://mobisafar.com/assets/images/dmt-money-transfer.png',
            title: 'Fund Transfer to all PSUs & Private Banks'
        },
        {
            imageUrl: 'https://mobisafar.com/assets/images/dmt-doorstep.png',
            title: 'Near Doorstep Remittance Facility'
        },
        {
            imageUrl: 'https://mobisafar.com/assets/images/dmt-cost-effective.png',
            title: 'Cost Effective'
        },
        {
            imageUrl: 'https://mobisafar.com/assets/images/dmt-safe-secure.png',
            title: 'Safe and Secure Transaction'
        },
        // {
        //     imageUrl: 'https://mobisafar.com/assets/images/dmt-free-sms-alerts.png',
        //     title: 'Free SMS Alerts on Every Transaction'
        // },
    ];

    const data2 = [
        {
            title: 'UPI-ATM is an Interoperable Cardless Cash Withdrawal (ICCW) service that facilitates participating banks customers who are live on UPI, to withdraw cash from any participating banks ATMs (enabled for UPI-ATM) without using their card.'
        },

    ];

    const bottomListItems = [
        'Easy Withdrawal without Debit card',
        'Easy Withdrawal without Debit card',
        'Easy Bank settlement'
    ];

    return (
        <div>
            <div className="aeps-content">
                <h2 style={{ textAlign: "left", marginBottom: 40 }}>QR ATM </h2>
                {/* <div className='row align-items-center'>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-3 col-6">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div> */}
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4>{item.title}</h4>
                        </div>
                    ))}
                </div>
                <div className='d-flex gap-5'>
                    <div className='text-start'>
                        <img src='https://www.mobisafar.com/assets/images/qr-atm-options.png' style={{ width: 416, }}></img>
                        <h3 style={{ color: "#043b5a", fontSize: 28, pb: 0, fontWeight: 600 }}>Key features</h3>
                        <ul className="bottom-list">
                            {bottomListItems.map((item, index) => (
                                <li key={index}>
                                    <div className='d-flex  gap-2' style={{ padding: "5px 0" }}>
                                        <div>
                                            <img src={dot} style={{ width: 17, marginTop: 6 }}></img>
                                        </div>
                                        <div >
                                            <p>{item}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        <div className='loginlink'>
                    <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                </div>
                    </div>
                    <div>
                        <img src={qrcsp} className='' style={{width:183,}}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QrAtmService;
