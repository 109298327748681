import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import kgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import sellanytime from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import safety from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import buylow from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'
import  physicalgold from './img/digitalgold/aeps-aadhaar-to-aadhaar.png'



const CspDematAccount = () => {
    

    const data2 = [
        {
            title: 'A demat account also known as a dematerialized account is a type of electronic account used to hold securities such as stocks, bonds, mutual funds, and other financial instruments in digital form. It is a safe and convenient way to hold, trade, and manage investments without physical documents or certificates.The Indian markets over the last 10 years have given 12-13% compounded on the benchmark level.'
        },
    ];

    const bottomListItems = [
        {
            title: 'Guaranteed 24K Gold',
            paragraph: 'Over 4.2 million Demat accounts were opened in December 2023, trends indicate an exponential Growth'
        },
        {
            title: 'Sell anytime from home',
            paragraph: 'There has been a sizeable influx in the first time investors who are  in the age group of 25-35 years          '
        },
        {
            title: 'Earn income on gold',
            paragraph: 'People who have been working from 5-10 years and in an annual salary bracket of 5-15 lacs income are participating in the market'
        },
        {
            title: 'Safety guaranteed',
            paragraph: 'Significant traction with regard to participation in stock markets have been observed from Tier 2 and 3 geographies'
        },
    ];



    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Demat Account</h1>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000" }}>{item.title}</h4>
                        </div>
                    ))}
                </div>
                <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>Key market highlights  are as below :-</h3>
                <ul className="bottom-list">
                    {bottomListItems.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div>
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
                <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>

            </div>
        </div>
    );
};

export default CspDematAccount;
