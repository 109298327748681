import React from 'react'
import './AboutHome.css'
import AboutImg from './aboutimg.jpg'

const AboutHome = () => {
    return (
        <div className='about-home-container'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className='about-left-content text-white d-flex flex-column about-home'>
                            <div className=' about-home-content pdiv d-flex flex-column '>
                                <p className=''>
                                    {/* CSP is a digitally powered, secure and simple platform enabled to provide multiple (300+) services across Banking, Bill Payment, Finance, Legal, Government, Travel etc. */}
                                    CSP is an initiative by CSP India Network Private Limited. It draws its inspiration from ‘Customer Service Point’. ‘Customer Service’ is at the focal point in all our endeavors and it is our relentless pursuit to have customer centric products, offerings and services on our platform.
                                </p>
                                <p className=''>
                                    We aspire to create a network of CSPs or ‘Customer Service Points’ across the length and breadth of Bharat and take digital and financial empowerment to the roots by providing opportunities of income for Individuals partnering with us as our ‘Grahak Pratinidhi’ and by bringing convenience in terms of access, agility and assistance to our customers
                                </p>
                                <p>
                                    We are committed in percolating financial inclusion and opportunities of growth in underserved geographies towards building a ‘Digital Bharat.’
                                </p>
                                <p>
                                    We are an evolving Service Aggregator, located in Pune. We have been recognized as a ‘Start up’ by DPIIT, Startup India
                                    With the three T’s of ‘Trust’, ‘Transparency’ and ‘Togetherness’ as our core values, we see CSP maturing as a brand emanating these values!
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center justify-content-center'>
                        <img className='about-home-img' src={AboutImg} alt='aboutimg' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutHome