import React from 'react'
import './ReadMore.css'

const AEPS = () => {
    return (
        <>
            <div className='read-more-container'>
                <div className='read-more-main-container d-flex align-items-center justify-content-center'>
                    <div className='header text-center text-white'>
                        <h1>Aadhaar Enabled Payment System</h1>
                    </div>
                </div>
            </div>
            <div className='read-more-main-container'>
                <div className='row mt-5'>
                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                        <div className='blog-intro'>
                            <div className='intro row'>
                                <h2>Introduction</h2>
                                <p>
                                    Convenience’ has always been a very comforting word to every individual and no one deny availing the same. Well, most of us have experienced and discussed convenience related to many facets of our lives but, we would unanimously agree that most recently the attribute of convenience has been attached to something that’s quite unconventional in nature and has bought a paradigm shift in the related industry, yes you might have almost guessed it by now – ‘the mode of digital payment ‘ in the financial and banking industry.
                                </p>
                                <p>
                                    We must acknowledge and appreciate the efforts put in by the Government of India that it has gained immense momentum in the field of “AEPS – Aadhaar Enabled Payment System ‘ within few years of its inception. It is truly commendable that the 12 digit unique identification number has emerged as the the world’s largest biometric ID system.
                                </p>
                                <p>
                                    AEPS is a big boon for the population residing in the rural parts of India – which contributes to a significant population chunk of India. In the rural regions, it became highly challenging for the villagers to walk to the bank in order to execute monetory transactions. But, with the AEPS feature, they can easily undertake all monetory transactions by visiting the nearby P.O.S. ( point of sale) correspondent and by using their UIAD number.
                                </p>
                                <p>
                                    The dream of ‘Digital India ‘as envisioned by our prime minister Narendra Modi ji was a real gift for the people of India. The pandemic that hit all of us in the year 2019 – 2021 showed us the value of going digital. Starting from education, medical, financial services, banking services, office work everything could be executed successfully from the comfort of our home owing to the provision of high speed internet to each household either via mobile data or wireless network. The focus that was given to digitization of India served as a saviour during the tough period of lockdown.
                                </p>
                                <p>
                                    The vision of Digital India was never a lopsided one wherein focus was only on the urban India. Instead, the major focus was to revolutionise the ‘Bharat’ as we may wish to call that resides in the villages, where people might not even have a bank account or even if they might have one they might dread to operate it owimg to the challenges faced by them in the form of travel, unawareness, lack of confidence, lack of communication,etc.
                                </p>
                                <p>
                                    Empowering this section of the Indian population was necessary. This is how the AePS launched in the year 2016 proved to be a gamechanger. It was launched to facilitate banking services by using the electronic identification system Adhaar in the rural areas that had no banks in its vicinity.
                                </p>
                                <p>
                                    India has always been ahead in the curve when it has come to adapting to new technology and the same holds true for the adoption and maturing in the field of AePS and most importantly it has struck the right chord with its target group – the rural population.
                                </p>
                                <p>
                                    The success of any product lies in its USP – unique selling proposition and for AePS its USP lies in the fact that it is hassle free and absolutely safe. It neither requires a smartphone nor a debit card or even an application. All you need is your Adhaar number and biometric authentication in place. That’s it with these two things you can avail a host of banking services like, withdrawal, deposit, fund transfer and all of these conducted on your behalf by the P.O.S. correspondent.
                                </p>
                                <p>
                                    Such an arrangement has been very beneficial to access the bank account with much ease especially for those who majorly depend of Government subsidies. With AePS the DBT (the process of directly transferring the subsidy amount into the person’s account by the Government instead of asking the Government representative to hand it over to the person intended to receive it) distribution would have become very difficult.
                                </p>
                                <p>
                                    The success and growth of the AePS can be easily gauged with the fact that in the present time more than 131 crore adult Indians registered with Aadhaar are having seamless access to banking, direct benefit services and insurance services. On a daily basis more than 5 crore Aadhaar based identity authentications are done along with more than 40 crore last mile banking transactions are conducted every month on the AePS system.
                                </p>
                            </div>
                            <div className='intro row'>
                                <p>
                                    The below data will also add perspective to the growth achieved by the AePS system in the recent past:
                                </p>
                                <table className="table aeps-table tavble-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Financial Year</th>
                                            <th scope="col">Volume (in lakhs)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>2018-19 </td>
                                            <td>2,32,602</td>
                                        </tr>
                                        <tr>
                                            <td>2019-20</td>
                                            <td>3,40,025</td>
                                        </tr>
                                        <tr>
                                            <td>2020-21 </td>
                                            <td>4,37,445</td>
                                        </tr>
                                    </tbody>
                                    <p className='mt-3'>Source: RBI</p>
                                </table>
                            </div>
                            <div className='intro row'>
                                <p>
                                    Today, more than 131 crore adults registered with Aadhaar have seamless access to banking, insurance and other direct benefit services. Over 5 crore Aadhaar-based identity authentications are daily done online across the country, even as more than 40 crore last mile banking transactions are being performed every month on the AePS system.
                                </p>
                                <p>
                                    There is a huge potential for the AePS system in India and there are many more resources yet to to be tapped through this innovative system. It will not be wrong to say that the ‘ball has started rolling’. It is very impressive to witness immense growth on this field and how the ‘Digital India’ campaign has gained a strong foot not only in rural India but also among the urban population.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AEPS