import React from 'react'
import './BPartners.css'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

// imgs

import Mob1 from './1.png'
import Air2 from './2.png'
import Razor7 from './7.png'
import BBPs from './BBPS_Logo.png'
import ICICI8 from './8.png'
import Yes9 from './9.png'
import NSDL10 from './10.png'

const options = {
    autoplay: true,
    loop: true,
    margin: 15,
    dots: false,
    slidetransition: "linear",
    autoplayTimeout: 4500,
    autoplayHoverPause: false,
    autoplaySpeed: 4500,
    responsive: {
        0: {
            items: 1
        },
        500: {
            items: 2
        },
        600: {
            items: 3
        },
        800: {
            items: 4
        },
        1200: {
            items: 5
        }
    }
};

const Partners = () => {
    return (
        <div className='partner-container pb-5'>
            <h2 className='d-flex text-center justify-content-start align-items-center pb-2'>Our Trusted Partners</h2>
            <div className='partner-main-container'>
                <div className='partner-companies py-5'>
                    <div className='row d-flex align-items-center justify-content-center'>
                        <OwlCarousel className="slider-items owl-carousel"  {...options}>
                            <div className="item d-flex align-items-center"><img src={Mob1} alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={Air2} alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={BBPs} className='bbps-logo' alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={Razor7} alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={ICICI8} alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={Yes9} alt='owlitem' /></div>
                            <div className="item d-flex align-items-center"><img src={NSDL10} alt='owlitem' /></div>
                        </OwlCarousel>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partners