import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'


const Aepsque = () => {




    const ServicesAeps = [
        {
            title: 'Train Booking',
            paragraph: 'Help your customers bypass queues for Ticket booking by bringing them the convenience of booking. We bring this to you in partnership with our IRCTC approved partner.'
        },
    ];

    const bottomListItems = [

        {
            "question": "What is Aadhaar?",
            "answer": "Aadhaar is an unique number that is assigned to residents of India by the Unique Identification Authority of India (UIDAI)."
        },
        {
            "question": "What is Aadhaar Enabled Payment System?",
            "answer": "Aadhaar Enabled Payment System is a payment service empowering a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled bank account and perform basic banking transactions like balance enquiry, cash deposit, cash withdrawal, remittances through any BC counter ( As per NPCI)"
        },
        {
            "question": "What are the Benefits of AePS services?",
            "answerList": [
                "Aadhaar enabled Payment System is easy to use, safe and secure payment platform to avail benefits by using Aadhaar number & biometrics.",
                "Aadhaar enabled Payment System is based on the demographic and biometric/iris information of an individual, it eliminates the threat of any fraud and non-genuine activity.",
                "Aadhaar enabled Payment System facilitates inter-operability across banks in a safe and secured manner",
                "Reaching the unreached - The model enable banks to extend financial services to the unreached clients beyond their branch network as beneficiaries of the BCs are mostly located at unbanked and underbanked areas."
            ]
        },
        {
            "question": "What are the services available under AePS at any CSP?",
            "answerList": [
                "Balance Enquiry",
                "Aadhaar to Aadhaar Fund Transfer",
                "Cash Withdrawal",
                "Cash Deposit"
            ]
        },
        {
            "question": "Who can use AEPS?",
            "answer": "All Indian residents holding a valid Aadhaar card and a bank account can use this payments service."
        },
        {
            "question": "How can a user enable this service?",
            "answer": "To start using AEPS, first link your bank account with your Aadhaar number. Then, contact your bank and let them know that you want to start using AEPS."
        },
        {
            "question": "What are the requirements for using AePS?",
            "answer": "To use the AePS facility, the following is required:\nAadhaar card should be linked with the bank account\nAadhaar number\nFingerprint biometric of the Aadhaar card holder"
        },
        {
            "question": "Should the payee/beneficiary also have AEPS to receive funds?",
            "answer": "It is not necessary that the payee or beneficiary has AePS to receive funds"
        },
        {
            "question": "Is it possible to link multiple bank account to your Aadhaar card?",
            "answer": "The same is possible"
        },
        {
            "question": "Can those without Aadhaar cards use this service?",
            "answer": "To avail this service Aadhar card is a mandatory requirement"
        },
        {
            "question": "Should the bank account be linked to Aadhar for AEPS?",
            "answer": "The bank account needs to be linked with your Aadhaar."
        },
        {
            "question": "What happens if a customer enters an incorrect Aadhaar Number or chooses a Bank where he doesn’t have an account?",
            "answer": "If the customer enters incorrect Aadhaar number/ selects an incorrect bank where he/ she does not have an Aadhaar linked bank account, the transaction will decline with an appropriate response message. Further, as a customer may link his/ her Aadhaar with multiple banks, customer should select the correct bank, from where he wishes to perform transaction. In case, customer has more than one account with the selected bank then only the primary account will be debited and customer cannot make a selection of bank account at the time of transaction."
        },
        {
            "question": "Does the customer specifically need to register with his Bank to be able to transact through AEPS?",
            "answer": "Ideally No, but linking Aadhaar Number with Bank account is a pre-requisite. However, registration process shall be as per the procedures laid down by the Issuer bank providing the service. Please check from the bank where you hold the Aadhaar linked bank account."
        },
        {
            "question": "Who is an Issuer Bank?",
            "answer": "Issuer is the bank in which the user hold his/her account and Aadhaar is mapped for doing AEPS Transactions."
        },
        {
            "question": "Who is an acquirer Bank?",
            "answer": "The bank which has acquired the transaction or the bank whose device has been used is acquirer bank"
        },
        {
            "question": "What is an ON Us transaction?",
            "answer": "An intra-bank (ON-US) transaction where an Aadhaar initiated transaction has effects only in accounts within one and same bank and does not necessitate an interbank settlement. A Customer can use Account holding bank terminal deployed on the field for availing AePS Service."
        },
        {
            "question": "What is an OFF-US transaction?",
            "answer": "An inter-bank (OFF-US) transaction is one where there is movement of funds from one bank to another necessitating an interbank settlement. Customer can approach other Bank terminals for availing AePS Service."
        },
        {
            "question": "The Customer has multiple accounts linked to his/her Aadhaar number in his/her bank. How can he/she specify the account number from which the transaction needs to be done?",
            "answer": "If customer has multiple account within the same Bank linked to Aadhaar, AEPS services will work with only the primary account associated with the Aadhaar. Customer need to get in touch with his/her bank to specify/change the primary account linking."
        },
        {
            "question": "If a customer has multiple accounts linked to his Aadhaar number across multiple Banks, which account will be debited for withdrawal?",
            "answer": "Since the accounts are held with different (multiple) Banks, Customer will have a choice to select the Bank name in the AEPS app from where the transaction need to be done."
        },
        {
            "question": "Does the customer need to carry Aadhaar Card for transacting?",
            "answer": "Carrying Aadhaar card is not mandatory but linkage of Aadhaar Number with Bank account is a prerequisite for transaction to be processed successfully."
        },
        {
            "question": "Is there any limit on AEPS Cash Withdrawal and Fund Transfer transactions? If so what are the limits.",
            "answer": "As per the NPCI circular dated 2nd November 2023, banks have been advised to allow their customers AePS cash withdrawal and BHIM aadhar Pay transactions with a cumulative limit of 50000/- per month. It is recommended to set the limit on a 30 days rolling basis."
        },
        {
            "question": "The customer has a Joint Account with other Bank. Will AEPS services offered by AePS available to me? Will additional consent of Joint Account holders will be required?",
            "answer": "In case Aadhaar is linked to the joint account and where the operation condition is either or survivor, then services can be availed using any of the Aadhaar details."
        },
        {
            "question": "Is there any age criteria to avail AEPS services? Can a minor above 10 years of age, avail such services? Can a minor below age of 10 years, avail such services?",
            "answer": "It is allowed for minors, but it depends on the issuer bank."
        },
        {
            "question": "Customer account has got debited and he/she has received confirmation from his/her Bank but transaction in MATM/CBS could not be completed?",
            "answer": "Possibly, the transaction has failed. The refund will go back to the customer A/C but it might take 5 working days. If the amount is not credited back within 5 days, same can be raised with bank they hold the account."
        },
        {
            "question": "Where do a customer register a complaint for failed transaction?",
            "answer": "The customer can raise a dispute/compliant with the bank they hold the account. Bank will further raise it with the concerned bank via NPCI’s Dispute Management System."
        },
        {
            "question": "How to Use the AePs Facility?",
            "answer": "To utilize the AePS facility, an Aadhaar card holder with a linked bank account can follow these steps:\n1. Visit a local CSP\n2. Enter the Aadhaar number into the Point of Sale (PoS) machine.\n3. Select the transaction type and specify the bank’s name.\n4. Enter the transaction amount to proceed with the transaction.\n5. Provide biometric authentication (fingerprint or iris scan) to confirm the payment.\n6. Upon successful completion, a receipt will be issued for the transaction."
        },
        {
            "question": "Who are the entities involved in carrying out an AePs transaction?",
            "answer": "A number of entities are involved in facilitating such transactions:\nThe individual wishing to carry out the transaction.\nThe intermediary, which could be a merchant or store owner, or a banking correspondent, through whom the transaction is conducted.\nThe Aadhaar-enabled bank.\nUIDAI, responsible for fingerprint authentication.\nNPCI (National Payments Corporation of India), responsible for transaction settlement."
        },
        {
            "question": "What are the steps taken by NPCI to control fraud in AePS?",
            "answer": "NPCI asked banks to implement the measures relating to authentication of BCs/Agents from January 1, 2024, for an initial period of three months, after which the impact will be reviewed to decide the further course of action"
        },
        {
            "question": "What is BBPS?",
            "answer": "Bharat Bill Payment System (BBPS) is an integrated platform that enables secure and convenient electronic payment of bills."
        },
        {
            "question": "How does BBPS work?",
            "answer": "BBPS works by connecting customers, banks, and billers through a standardized platform, allowing seamless bill payments electronically."
        },
        {
            "question": "Who regulates BBPS in India?",
            "answer": "BBPS is regulated by the National Payments Corporation of India (NPCI), ensuring compliance with standards and guidelines."
        },
        {
            "question": "What types of bills can be paid through BBPS?",
            "answer": "BBPS supports a wide range of bill categories, including electricity, water, gas, telecom, DTH, and more."
        },
        {
            "question": "Is BBPS available for both individual and business bill payments?",
            "answer": "Yes, BBPS caters to both individual consumers and businesses for their bill payment needs."
        },
        {
            "question": "Are there any charges associated with using BBPS?",
            "answer": "Service providers may apply nominal charges, but BBPS itself does not levy any fees on customers."
        },
        {
            "question": "What security measures are in place for BBPS transactions?",
            "answer": "BBPS adheres to stringent security protocols, including encryption and authentication, to ensure the safety of transactions."
        },
        {
            "question": "How long does it take for a bill payment to be processed through BBPS?",
            "answer": "BBPS transactions are typically processed in real-time, ensuring prompt payment confirmation."
        }



    ];


    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Frequently Asked Questions</h1>
                <ul className="bottom-list">
                    {bottomListItems.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div>
                                <div>
                                    <p><strong>{item.question}</strong></p>
                                    <p>{item.answer}</p>
                                    {item.answerList ? (
                                        <ul>
                                            {item.answerList.map((answerList, idx) => (
                                                <li key={idx}>{answerList}</li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>{item.answerList}</p>
                                    )}
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>


            </div>
        </div>
    );
};

export default Aepsque;
