import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'

const CspLoanMf = () => {
 

    const ServicesAeps = [
       
        {
            title: 'Loan against Mutual fund',
            paragraph: 'Help your customers get loans on their shares and mutual funds. The LAMF offering has the following features: Low Emi, Instant approval, No Pre payment charges, Retention of growth benefits, direct bank deposit, Flexible amounts   '
        },

    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left",marginLeft:20, marginBottom: 40 }}>Loan  Against Mutual Fund</h1>
                
      
                <ul className="bottom-list">
                    {ServicesAeps.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                     <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspLoanMf;
