import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import hiddencharge from './img/airandhotel/at-no-hidden-charges.png'
import zerocharge from './img/airandhotel/at-zero-booking-fee.png'
import lowprice from './img/airandhotel/at-lowest-price.png'

const CspAirBook = () => {

  

    const data2 = [
        {
            title: 'Online air ticket booking has revolutionized the way people travel, offering unprecedented convenience and flexibility. CSP platform brings to you the facility of online ticket booking, now you can guide your customers and provide comprehensive information on flight schedules, seat availability, and airline options, empowering passengers to make informed decisions tailored to their preferences and budget.'
        },

    ];


    const ServicesAeps = [

        {
            title: 'Air Booking',
            paragraph: 'Seamlessly book and confirm flight tickets for your customers CSP offers booking of domestic and international flights with prompt services and user-friendly interface. With the best routing options and quality deals, customers can trust CSP to provide an excellent booking experience.'
        },
    ];
    const benefits = [
        {
            title: 'First, and foremost, booking your flight online will provide you with a wide selection of flights which will vary depending on your destination, the airport, the airline and the departure time.',
        },
        {
            title: 'Simple to use, Time saving and cost effective platform',
        },
        {
            title: 'Lowest airfare for your flight booking',
        },
        {
            title: 'Competitive Rates and 24 x 7 Personalized Support Service',
        },
        {
            title: 'Your company logo and address on the E ticket. Book, Sell and print tickets 24 x 7',
        },
        {
            title: 'Earn Significant Commissions',
        },
        {
            title: 'Fare Mark-up tool to enable you to earn more Commissions',
        },
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Flight Ticket Booking</h1>
                <ul className="bottom-list">
                    {ServicesAeps.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                     <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspAirBook;
