import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'

const CspPersonalLoan = () => {


    const ServicesAeps = [
        {
            title: 'Personal Loan',
            paragraph: 'A Personal loan is a loan that does not require collateral or security and is offered with minimal documentation. It has to be repaid in accordance to the agreed terms with the bank in easy equated monthly instalments'
        },
        {

            paragraph: 'Personal Loan offers a huge scope in the recent times as there has been a rising demand for small ticket loans: 85% of personal loans originations in FY22 were with a value of less than INR1 lakh. A significant contributor to this volume has been the revolutionization of loan applications and the approval process brought about by digital transformation, making credit more accessible through advanced algorithms and alternative credit scoring methods. Explore this potential of the lending industry by offering to your customers an array of loan products providing them with choice, speed and convenience.'
        },


    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginLeft: 20, marginBottom: 20, }}>Personal Loan</h1>
                {/* <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000" }}>{item.title}</h4>
                        </div>
                    ))}
                </div> */}
                {/* <h3 style={{ color: "#333", textAlign: "left", fontSize: 16, paddingBottom: 10 }}>Our Grahak Pratinidhi’s can now offer to their customers benefits
                    of the following lending products :-</h3> */}
                <ul className="bottom-list">
                    {ServicesAeps.map((item, index) => (
                        <li key={index}>
                            <div className='d-flex gap-2 pb-2'>
                                {/* <div>
                                    <img src={dot} style={{ width: 17, paddingTop: 6 }}></img>
                                </div> */}
                                <div>
                                    {/* <p><strong>{item.title}</strong></p> */}
                                    <p>{item.paragraph}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                      <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </ul>
            </div>
        </div>
    );
};

export default CspPersonalLoan;
