import React from 'react'
import EgovHome from './EgovHome/Egovhome'
import WhatisEgov from './WhatisEgov/WhatisEgov'
import EgovReq from './EgovReq/EgovReq'
import EgovSegment from './EgovSegment/EgovSegment'


const NewEgov = () => {
    return (
        <div className='about'>
            <EgovHome />
            <WhatisEgov />
            <EgovReq />
            <EgovSegment /> 

        </div>
    )
}

export default NewEgov