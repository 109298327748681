import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'

const CspRecharges = () => {
   

    const data2 = [
        {
            title: 'One of the most significant advantages of using BBPS for bill recharges is the convenience it offers. Whether it is topping up prepaid mobile phones, paying postpaid mobile bills, or settling electricity bills, BBPS provides a hassle-free experience, saving time and effort for users.'
        },
        {
            title: 'Transparency is another key feature of BBPS-enabled bill recharges. Users receive instant confirmation of their transactions, along with digital receipts for their records.'
        },
        {
            title: 'Help the customers in your locality to make their recharge payments hassle free, instant and transparent'
        },
    
    ];



    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Recharges</h1>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000" }}>{item.title}</h4>
                        </div>
                    ))}
                      <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </div>
               
               
            </div>
        </div>
    );
};

export default CspRecharges;
