import React from 'react';
import '../../Banking/CspBankingServices/InnerServices/InnerServices.css';
import dot from '../../Banking/CspBankingServices/InnerServices/dot-circle-svgrepo-com.svg'
import mobilerecharge from './img/billpayment/bbps-mobile-recharge.png'
import electricity from './img/billpayment/bbps-electricity.png'
import gas from './img/billpayment/bbps-lpg-gas.png'
import water from './img/billpayment/bbps-water-bill.png'
import landline from './img/billpayment/bbps-landline.png'
import broadband from './img/billpayment/bbps-broadband.png'
import fasttag from './img/billpayment/bbps-fastag.png'
import CspInsurancePremium from './img/billpayment/bbps-insurance-premium.png'
import muncipaltax from './img/billpayment/bbps-municipal-tax.png'
import playstore from './img/billpayment/bbps-google-play-voucher.png'
import emicollection from './img/billpayment/bbps-emi-collection.png'




const CspBillPayment = () => {
    const data = [
        {
            imageUrl: mobilerecharge,
            title: 'Mobile Postpaid'
        },
        {
            imageUrl: electricity,
            title: 'Electricity'
        },
        {
            imageUrl: gas,
            title: 'Gas/LPG'
        },
        {
            imageUrl: water,
            title: 'Water'
        },
        {
            imageUrl: landline,
            title: 'Landline'
        },
        {
            imageUrl: broadband,
            title: 'Broadband'
        },
        {
            imageUrl: fasttag,
            title: 'Fastag'
        },
        {
            imageUrl: emicollection,
            title: 'EMI Collection'
        },
        {
            imageUrl: CspInsurancePremium,
            title: 'Insurance Premium'
        },
        {
            imageUrl: muncipaltax,
            title: 'Municipal Tax'
        },
        {
            imageUrl: playstore,
            title: 'Google Play Digital Voucher'
        },


    ];

    const data2 = [
      
        {
            title: 'The Bharat Bill payment system is a Reserve Bank of India (RBI) conceptualised system driven by National Payments Corporation of India (NPCI). It is a one-stop ecosystem for payment of all bills providing an interoperable and accessible “Anytime Anywhere” Bill payment service to all customers across India with certainty, reliability and safety of transactions.'
        },
        {
            title: 'Bharat BillPay has multiple modes of payment and provides instant confirmation of payment via an SMS or receipt. CSP brings to you BBPS to help you offer to your customers a wide range of Bill collection categories like electricity, telecom, DTH, gas, water bills, etc. school fees, institution fees, credit cards, fastag recharge, local taxes, housing society payments, etc. at one single window.'
        },
    ];

    const bottomListItems = [
        'lorem',
        'lorem',
        'lorem',
        'lorem ',
        'lorem',
        'lorem'
    ];
    const Objectives = [
        'To empower a bank customer to use Aadhaar as his/her identity to access his/ her respective Aadhaar enabled bank account and perform basic banking transactions like cash deposit, cash withdrawal, Intrabank or interbank fund transfer, balance enquiry and obtain a mini statement through a Business Correspondent',
        'To sub-serve the goal of Government of India (GoI) and Reserve Bank of India (RBI) in furthering Financial Inclusion.',
        'To sub-serve the goal of RBI in electronification of retail payments',
        'To enable banks to route the Aadhaar initiated interbank transactions through a central switching and clearing agency.',
        'To facilitate disbursements of Government entitlements like NREGA, Social Security pension, Handicapped Old Age Pension etc. of any Central or State Government bodies, using Aadhaar and authentication thereof as supported by UIDAI.',
        'To facilitate inter-operability across banks in a safe and secured manner.',
        'To build the foundation for a full range of Aadhaar enabled Banking services.'
    ];
    const ServicesAeps = [
        'Cash Withdrawal',
        'Cash Deposit',
        'Balance Enquiry',
        'Aadhaar to Aadhaar Fund Transfer',
        'Mini Statement',
        'Best Finger Detection'
    ];

    return (
        <div>
            <div className="aeps-content">
                <h1 style={{ textAlign: "left", marginBottom: 40 }}>Key Services throught BBPS</h1>
                <div className='row '>
                    {data.map((item, index) => (
                        <div key={index} className="col-lg-2 col-4  col-content">
                            <img src={item.imageUrl} alt={item.title} />
                            <h3>{item.title}</h3>
                        </div>
                    ))}
                </div>
                <div className='aeps-all-content'>
                    {data2.map((item, index) => (
                        <div key={index}>
                            <h4 style={{ color: "#000", }}>{item.title}</h4>
                        </div>
                    ))}
                      <div className='loginlink'>
                        <a href='/workflow/login/register'><p>For details log in to csp.in </p></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CspBillPayment;
