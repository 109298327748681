import React, { forwardRef } from 'react';
import CSPTitle from '../Home/Assets/tagline.png'
export const ThirdSection =  forwardRef((props, ref) => {
    const sectionData = [
        {
            id: 1,
            title: 'Instant Opportunity',
            subtitle: 'Business from your Neighbourhood'
        },
        {
            id: 2,
            title: 'Instant Access',
            subtitle: 'Onboarding & document execution take twice as long'
            // subtitle: 'Onboarding & document execution take '
        },
        {
            id: 3,
            title: 'Instant Income',
            subtitle: 'Real time Commission on every Transaction*'
        },
        {
            id: 4,
            title: 'Instant Support',
            subtitle: 'Help is a click or a call away'
        },
    ]
    return (
        <div className='bg-light' ref={ref}>
            <div className='container cspin-growth' style={{
            }}>
                {/* <h2 class="text-center fw-bold mb-1">Make yourself part of an exciting growth journey!</h2>
                <h2 class="text-center fw-bold mb-3" style={{ color: '#16b287' }}>Maximize your
                    customer Base</h2> */}
                <div className='text-center'>
                    <img src={CSPTitle} className='img-fluid cspin-incomefix' alt='cspin' />
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-9 col-md-10 col-sm-12 mx-auto csp-info'>
                        <p className='mb-4 text-center' >
                            CSP brings to you various offerings which form a part of daily utility. Powered by services like
                           <span style={{fontWeight:600,color:"#00B087"}}> Money Transfer, Cash Withdrawal and Deposit, Bill Payments, Rail and Bus ticketing, Digital
                            Gold, Personal and Business Loan etc.</span> CSP empowers you to serve
                            a wide range of customer segment, acquire new customers, enhance customer engagement,
                            retention and cross sell avenues. Above all, it helps you start your own Customer Service Point.
                            So, Why Wait? Source more, Serve more, Earn more!
                        </p>
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='col-8 mx-auto'>
                        <div className='row'>
                            {sectionData.map((sec, i) => (
                                <div key={sec.id + i} className='col-lg-6 col-md-6 col-sm-12 col-12'
                                    style={{ marginTop: sec.id === 2 || sec.id === 4 ? 25 : 0 }}
                                >
                                    <div className='p-4 rounded' style={{ backgroundColor: '#2c5363', }}>
                                        <span className="rounded-pill p-1 d-inline-block" style={{ backgroundColor: '#e56b6b' }}></span>
                                        <h5 class="fw-600 mt-2 text-white" style={{ fontWeight: 600, fontSize: '1.25rem', lineHeight: 1.2 }}>{sec.title}</h5>
                                        <p className='text-white mb-1'>{sec.subtitle}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
})