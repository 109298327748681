import React from 'react'
import './style.css'
import ShieldCard from '../WhyCSP/dashboard.png'
import SimpleCard from '../WhyCSP/shield-card.png'
import SmartCard from '../WhyCSP/smart-bulb.png'
export const NinthSection = () => {
    const sliderData = [
        {
            id: 1,
            title: "Simple",
            icon: ShieldCard,
            subtitle: 'Registration in 3 steps: Instant Activation, Easy Interface, Single Sign in'
        },
        {
            id: 2,
            title: "Secure",
            icon: SimpleCard,
            subtitle: 'Secure Data Exchange Mechanism, Authorization and Authentication Process'
        },
        {
            id: 3,
            title: "Smart",
            icon: SmartCard,
            subtitle: 'Single wallet, Instant Settlement, Wallet load and Commission, Analytical Dashboard'
        },
    ]
    return (
        <div className="container ninth-section" style={{ paddingTop: 30, paddingBottom: 120 }}>
            {/* <h2 className="fw-bold text-center mb-4" style={{ color: '#2c5363' }}>Custom-Built Solutions For Every Industry Sector</h2> */}
            <div className='row'>
                {
                    sliderData.map((sl) => (
                        <div key={sl.id} className='col-lg-4 col-md-4 col-sm-12 p-3'>
                            <div className='w-100 p-4 h-100' style={{ backgroundColor: '#2c5363', borderRadius: 5 }}>
                                <div className='p-3 bg-white rounded-3 d-flex justify-content-center'>
                                    <img src={sl.icon} className='img-fluid' style={{ height: 50 }} />
                                </div>
                                <h6 className='mt-4 fw-bold text-white'>{sl.title}</h6>
                                <p className='text-white mt-4'>
                                    {sl.subtitle}
                                </p>
                            </div>
                        </div>
                    ))}
            </div>
        </div>
    )
}
